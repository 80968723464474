import React, { useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled, { keyframes } from 'styled-components';

export default function Baffle_Text(props) {

    useEffect(() => {
        setTimeout(()=>{
            props.parentMethod()
        }, props.callMethodTime)
     
    }, [props.callMethodTime])
    

    const Dash = keyframes`
    from {
        stroke-dashoffset: 4540;
      }
      to {
        stroke-dashoffset: 0;
      }
    
    `;
    const StyledSVG = styled.svg`
    .animated_outline{
        fill:none;stroke:#FFFFFF;stroke-miterlimit:10;
    }
    .st1{
        fill:#FFFFFF;
    }
    width: 60vw;
    .animated_outline {
        stroke-dasharray: 4540;
        stroke-dashoffset: 4540;

        animation: ${Dash} 2s linear;
      }
`;

    return (
        <React.Fragment>
            <StyledSVG id="Layer_1" x="0px" y="0px" viewBox="0 0 1118 648">
                <g id="GROTH">
                    <path className="animated_outline" d="M154.5,434.5c0,0-2-2-4,0s-5,3-12,2s-66-8-95,4s-54,33-32,67s76,34,82,34s101-1,101-1v-3c0,0,0-1-1-2s-8-1-8-1
                s-7-2-7-8s-1-43,3-49s14-7,14-7v-4h-67v4c0,0,18,5,18,8s0,42,0,42s-1,8-12,8s-58,6-79-19s-20-54,29-63s74,18,74,18h7L154.5,434.5z"
                    />
                    <path className="animated_outline" d="M388.5,518.5c-9-16-36-37-44-41v-1c0,0,44-11,39-26s-11-15-25-15s-132-1-132-1v4c0,0,18,0,20,15l-1,73
                c0,0-1,9-20,8v5h70v-3c0,0-16,0-16-10s0-36,0-36l32-6c0,0,47,30,49,43c0,0,2,4-10,6l-2,6h66v-6
                C414.5,533.5,397.5,534.5,388.5,518.5z M280.5,479.5c0,0,0-9,0-17s3-17,3-17s42-2,48-2s21,7,14,17S280.5,479.5,280.5,479.5z"/>
                    <path className="animated_outline" d="M643.5,481.5c-4-51-114-54-114-54c-45,1-70,19-84,27s-31,30-1,61s103,26,110,25S647.5,532.5,643.5,481.5z
                M537.5,528.5c0,0-61-2-71-43s59-45,65-45s66,1,72,46S537.5,528.5,537.5,528.5z"/>
                    <path className="animated_outline" d="M685.5,429.5c0,0,149,2,153-1s20,34,20,34l-6,2c0,0-4-25-74-23l3,84c0,0-1,7,7,8s13,1,13,1v6l-79-1l-1-5
                c0,0,23,5,21-19s-4-74-4-74s-52-5-69,22l-6-2l15-35C678.5,426.5,679.5,428.5,685.5,429.5z"/>
                    <path className="animated_outline" d="M875.5,427.5l84,1v5c0,0-22-3-23,8s0,18,0,18l99,2v-14c0,0-1-13-14-13s-11-1-11-1v-5h89v5c0,0-24-3-23,18
                s3,69,3,69s0,15,15,17l7,2v4l-86-1v-5c0,0,24,5,23-16s-3-49-3-49l-98-1l3,54c0,0,4,12,20,12c0,0,4,4-1,5s-81-2-81-2v-4
                c0,0,20-1,20-6s-2-82-2-82s1-15-21-15V427.5z"/>
                    <path className="animated_outline" d="M302.5,598.5l5-2c0,0-2-5-8-6s-15-1-19,3s-3,10,0,12s8,4,12,5s10,3,10,7s-5,7-7,7s-11,1-15-6h-6c0,0,2,9,9,10
                s14,1,19-2s6-5,6-10s-5-8-9-9s-10-3-12-4s-6-6,2-9S302.5,598.5,302.5,598.5z"/>
                    <polygon className="animated_outline" points="346.5,595.5 346.5,605.5 368.5,605.5 368.5,610.5 346.5,610.5 346.5,624.5 368.5,624.5 368.5,628.5 
                340.5,628.5 340.5,590.5 369.5,590.5 369.5,595.5 346.5,595.5 	"/>
                    <polygon className="animated_outline" points="401.5,590.5 401.5,628.5 408.5,628.5 408.5,591.5 408.5,590.5 	" />
                    <polygon className="animated_outline" points="436.5,591.5 436.5,595.5 450.5,595.5 450.5,629.5 456.5,629.5 456.5,595.5 470.5,595.5 470.5,591.5 
                436.5,591.5 	"/>
                    <polygon className="animated_outline" points="555.5,592.5 551.5,596.5 561.5,596.5 561.5,630.5 561.5,631.5 568.5,631.5 568.5,592.5 	" />
                    <path className="animated_outline" d="M636.5,612.5c0,0,8-6,5-11s-5-8-16-9c0,0-13,1-15,7s0,10,6,12c0,0-8,2-8,9s6,11,11,12s15,0,18-2s6-4,6-8
                S641.5,613.5,636.5,612.5z M625.5,597.5c5,0,9,2,9,7s-6,5-9,5s-8,0-9-6S625.5,597.5,625.5,597.5z M626.5,628.5c-6,0-11-3-11-8
                s10-6,10-6c9-1,11,5,11,8S632.5,628.5,626.5,628.5z"/>
                    <path className="animated_outline" d="M697.5,593.5c0,0-21,0-21,14s23,13,23,13l-12,11l5,3l24-23v-4C717.5,593.5,697.5,593.5,697.5,593.5z
                M697.5,615.5c0,0-14,1-14-8s13-9,13-9c3,0,13,1,13,9S697.5,615.5,697.5,615.5z"/>
                    <path className="animated_outline" d="M780.5,614.5c5-1,6-5,6-8s-4-12-17-12s-17,7-17,12s6,8,6,8s-7,3-7,10s8,11,19,12s18-6,18-12
                S780.5,614.5,780.5,614.5z M769.5,599.5c7,0,10,4,10,7s-3,6-10,6c0,0-10-1-10-6S762.5,599.5,769.5,599.5z M769.5,631.5
                c-12,0-11-7-11-7c1-8,10-7,10-7c13-1,12,7,12,7S781.5,631.5,769.5,631.5z"/>
                </g>
                <g id="Käfer">
                    <path className="st1" d="M386.5,313.5c0,0-6-2-10,4c0,0-17,1-13-15c0,0,1,2,1,6c0,0,0,3,6-1l8-3c0,0,14-3,11,3S386.5,313.5,386.5,313.5
                z"/>
                    <path className="st1" d="M409.5,316.5c0,0,2,3-10,2c-10.5-0.9-13.3-0.2-13.9,0l6.9-3c0,0,1,0,1-4s9-5,9-5s6,0,7,2
                C409.5,308.5,408.5,315.5,409.5,316.5z"/>
                    <path className="st1" d="M427.5,307.5c-3,7-9,5-9,5c1,0,3.8-3.8,4-4c-8,3-18-6-14-9s13-6,18-6S430.5,300.5,427.5,307.5z" />
                    <path className="st1" d="M489.5,289.5l46,11l1-1L489.5,289.5z M489.5,289.5l46,11l1-1L489.5,289.5z M489.5,289.5l46,11l1-1L489.5,289.5
                z M650.5,307.5c-2-1-8,2-8,2c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4S650.5,307.5,650.5,307.5z M667.5,313.5
                c-1-8-7-7-10-4s-2,4-2,8c0,0,0,1-3,2C652.5,319.5,668.5,321.5,667.5,313.5z M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8
                c0,0,2,0-1,3c0,0,1,2,6-2S688.5,297.5,681.5,298.5z M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8c0,0,2,0-1,3c0,0,1,2,6-2
                S688.5,297.5,681.5,298.5z M667.5,313.5c-1-8-7-7-10-4s-2,4-2,8c0,0,0,1-3,2C652.5,319.5,668.5,321.5,667.5,313.5z M650.5,307.5
                c-2-1-8,2-8,2c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4S650.5,307.5,650.5,307.5z M489.5,289.5l46,11l1-1
                L489.5,289.5z M489.5,289.5l46,11l1-1L489.5,289.5z M650.5,307.5c-2-1-8,2-8,2c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1
                c0-2,5-2,7-4S650.5,307.5,650.5,307.5z M667.5,313.5c-1-8-7-7-10-4s-2,4-2,8c0,0,0,1-3,2C652.5,319.5,668.5,321.5,667.5,313.5z
                M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8c0,0,2,0-1,3c0,0,1,2,6-2S688.5,297.5,681.5,298.5z M681.5,298.5c0,0,2,2-1,2s-8,1-9,3
                s-2,6,3,8c0,0,2,0-1,3c0,0,1,2,6-2S688.5,297.5,681.5,298.5z M657.5,309.5c-3,3-2,4-2,8c0,0,0,1-3,2c0,0,16,2,15-6
                S660.5,306.5,657.5,309.5z M642.5,309.5c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4s0-8,0-8
                C648.5,306.5,642.5,309.5,642.5,309.5z M489.5,289.5l46,11l1-1L489.5,289.5z M1087.5,311.5c0,0-76,6-117,6s-196,2-196,2s-88,5-91,3
                c0,0-1-1,3-1s26-1,19-14c0,0,3-2,5,1c0,0-2,9-1,9c0,0,4-4,4-11s-1-11-5-14s-6-4-6-7l5,4c0,0,0-2-3-5s-4-4-7-4l-8-5c0,0-2-1-4,0
                l13,8c0,0,1,4,0,5s-6,1-6,1l6,4c0,0,1,3-2,2s-7-7-14-9s-19-4-37,0s-27,14-27,14l-9,10c0,0-6,5-15,4l-1,1l-60-2c0,0-68-1-74,1
                c0,0-1,0,0-3l76-2l52-1c0,0,6,1,8,3c0,0,2,1,1-1s-6-3-11-4s-7-1-7-1s25-42,89-35c0,0,3,0-1-2c0,0-32-18-131-17s-117,5-141,9
                s-47,13-47,13s-9,4-1-3s29-20,29-20s28-18,44-20c0,0,2,1-2,3s-10,5-10,5s-4,3,2,1s8-4,8-4l15-7c0,0,3-1,5,0s5-2,5-2l7-1
                c0,0,9-2,9-1s0,8,1,9c0,0,0,2-6,0s-6,2-6,2s0,4,5,6c0,0,0,2-3,2s-27,1-28,1s-4,2-4,3s12-1,12-1s27,0,43-3c0,0,8,0,6-7c0,0,1-2,3,0
                s3,2,3-1s3-2,3-2s2,11,6,11s5-5,3-8s-6-6-8-6s0-6,3-6s25-3,34,0c0,0,2,0,2,4h6c0,0-1-4,3-3s33,6,42,21c0,0,0-4-3-6s-6-7-5-8
                s15,14,15,18c0,0,56,4,81,16c0,0,2,1,1-1s-35-15-80-17c0,0-9-22-46-31s-127-18-207,39c0,0-16,11-28,22c0,0-7-1-10,3c0,0-2,2,0,4
                c0,0-3,4-4,8s-2,8,0,9s3-3,3-3s0-6,1-7s4,0,4,0s11-1,18-7s22-16,23-18s-1,0-1,0l-26,19c0,0-13,6-15,4s1-3,1-3s6-1,8-4s1-2,1-4
                s11-9,11-9s89.8-80.9,222.3-49.6c-17.5-3.9-118.3-21.7-208.3,55.6l-1,2c0,0-7,1-10,7s-25,17-27,18c0,0,12,0,26-11c0,0,2-2,3,2
                s8-2,8-2l13-8c0,0,26,2,34-5c0,0-12-1-21,3h-11c0,0,3-5,0-6s36-15,65-15s35,5,42,13s6,24,6,24s0,3,2,0v-11l9,2l-9-3v-1l10,1l-11-4
                c0,0,2-3,18,2c0,0,0,3-3,2l2,2l-4,19h-16v-4c0,0-4,13-7,13s-11,0-11,0s-3,3,0,3s11,1,18-2s77,0,77,0s4,1,0,3s-43-2-61,1l-25,1h-20
                c0,0,23-12,17-39c0,0-14-18-69,2s-35,21-81,28c0,0-2,1,0,2s8,0,8,0s3,5-3,6l11,3l-295,9c0,0-6,1-6,3s5,3,12,3s113-2,113-2
                s109,0,166-3s136-1,136-1l239-2l196-5l77-1l143-4c0,0,10,0,10-5S1087.5,311.5,1087.5,311.5z M699.5,289.5c3-1,3,3,3,3s2,1,5,4
                s1,6,1,6c0-3-7-9-7-9S696.5,290.5,699.5,289.5z M300.5,284.5c-4-4,5-4,5-4C305.5,285.5,300.5,284.5,300.5,284.5z M465.5,223.5
                c3-1,9,2,9,2s0,1-1,2h-6C467.5,227.5,462.5,224.5,465.5,223.5z M327.5,291.5c0,0-3-6,5-10s13-2,13-2S336.5,291.5,327.5,291.5z
                M482.5,285.5l82,18l2,1h-6l-17-3l10,3l-2,1l-65-14l60,14l-68,2L482.5,285.5z M417.5,320.5c-9,4-34,4-48,2s-14-9-14-15s10-14,10-14
                s1,0,1,1s1,2,2,2s0-2,0-2c4-5,3,1,3,1v2c0,3,13,3,17,2s6-3,5-5s3-3,3-1s5,6,12,0s12-6,12-6s8,2,4-1s4-3,4-3
                C445.5,307.5,426.5,316.5,417.5,320.5z M602.5,320.5v-5c8,0,8,5,8,5H602.5z M664.5,289.5c0,0,0,4-2,4s-2-1-2-3
                S664.5,289.5,664.5,289.5z M676.5,316.5c-15,6-19,6-19,6h-20c0,0-11-1-8-9s7-8,7-8s8-2,8-3c0,0,11,1,12-6c0,0,3,2,4-1h4l3,3
                c0,0,4,1,9-4c0,0,3-1,4,0c0,0,3,1,1-1s-3-3-9-3c0,0-3-2,4-2s10,2,10,3c0,0,0,6,4,8C690.5,299.5,691.5,310.5,676.5,316.5z
                M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8c0,0,2,0-1,3c0,0,1,2,6-2S688.5,297.5,681.5,298.5z M657.5,309.5c-3,3-2,4-2,8
                c0,0,0,1-3,2c0,0,16,2,15-6S660.5,306.5,657.5,309.5z M642.5,309.5c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4
                s0-8,0-8C648.5,306.5,642.5,309.5,642.5,309.5z M536.5,299.5l-47-10l46,11L536.5,299.5z M489.5,289.5l46,11l1-1L489.5,289.5z
                M650.5,307.5c-2-1-8,2-8,2c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4S650.5,307.5,650.5,307.5z M667.5,313.5
                c-1-8-7-7-10-4s-2,4-2,8c0,0,0,1-3,2C652.5,319.5,668.5,321.5,667.5,313.5z M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8
                c0,0,2,0-1,3c0,0,1,2,6-2S688.5,297.5,681.5,298.5z M681.5,298.5c0,0,2,2-1,2s-8,1-9,3s-2,6,3,8c0,0,2,0-1,3c0,0,1,2,6-2
                S688.5,297.5,681.5,298.5z M667.5,313.5c-1-8-7-7-10-4s-2,4-2,8c0,0,0,1-3,2C652.5,319.5,668.5,321.5,667.5,313.5z M650.5,307.5
                c-2-1-8,2-8,2c-5,0-3,2-3,2c0,3-4-1-4-1c0,2,1,5,4,8s4,1,4,1c0-2,5-2,7-4S650.5,307.5,650.5,307.5z M489.5,289.5l46,11l1-1
                L489.5,289.5z M489.5,289.5l46,11l1-1L489.5,289.5z M489.5,289.5l46,11l1-1L489.5,289.5z"/>
                    <path className="st1" d="M650.5,307.5c0,0,2,6,0,8s-7,2-7,4c0,0-1,2-4-1s-4-6-4-8c0,0,4,4,4,1c0,0-2-2,3-2
                C642.5,309.5,648.5,306.5,650.5,307.5z"/>
                    <path className="st1" d="M667.5,313.5c1,8-15,6-15,6c3-1,3-2,3-2c0-4-1-5,2-8S666.5,305.5,667.5,313.5z" />
                    <path className="st1" d="M679.5,312.5c-5,4-6,2-6,2c3-3,1-3,1-3c-5-2-4-6-3-8s6-3,9-3s1-2,1-2C688.5,297.5,684.5,308.5,679.5,312.5z" />
                    <path className="st1" d="M679.5,312.5c-5,4-6,2-6,2c3-3,1-3,1-3c-5-2-4-6-3-8s6-3,9-3s1-2,1-2C688.5,297.5,684.5,308.5,679.5,312.5z" />
                    <path className="st1" d="M652.5,319.5c3-1,3-2,3-2c0-4-1-5,2-8s9-4,10,4S652.5,319.5,652.5,319.5z" />
                    <path className="st1" d="M650.5,315.5c-2,2-7,2-7,4c0,0-1,2-4-1s-4-6-4-8c0,0,4,4,4,1c0,0-2-2,3-2c0,0,6-3,8-2
                C650.5,307.5,652.5,313.5,650.5,315.5z"/>
                    <path className="st1" d="M637.5,285.5c0,0-33,1-46,19l16,3c0,0,12-19,29-20C636.5,287.5,639.5,287.5,637.5,285.5z" />
                    <polygon className="st1" points="536.5,299.5 535.5,300.5 489.5,289.5 	" />
                    <polygon className="st1" points="536.5,299.5 535.5,300.5 489.5,289.5 	" />
                    <path className="st1" d="M449.5,282.5c0,0,0,2,4,4c0,0-2,2-1,3s5,1,5-2S458.5,281.5,449.5,282.5z" />
                    <path className="st1" d="M386.5,313.5c0,0-6-2-10,4c0,0-17,1-13-15c0,0,1,2,1,6c0,0,0,3,6-1l8-3c0,0,14-3,11,3S386.5,313.5,386.5,313.5
                z"/>
                    <path className="st1" d="M409.5,316.5c0,0,2,3-10,2c-10.5-0.9-13.3-0.2-13.9,0l6.9-3c0,0,1,0,1-4s9-5,9-5s6,0,7,2
                C409.5,308.5,408.5,315.5,409.5,316.5z"/>
                    <path className="st1" d="M427.5,307.5c-3,7-9,5-9,5c1,0,3.8-3.8,4-4c-8,3-18-6-14-9s13-6,18-6S430.5,300.5,427.5,307.5z" />
                    <path className="st1" d="M427.5,307.5c-3,7-9,5-9,5c1,0,3.8-3.8,4-4c-8,3-18-6-14-9s13-6,18-6S430.5,300.5,427.5,307.5z" />
                    <path className="st1" d="M399.5,318.5c-10.5-0.9-13.3-0.2-13.9,0l6.9-3c0,0,1,0,1-4s9-5,9-5s6,0,7,2c0,0-1,7,0,8
                C409.5,316.5,411.5,319.5,399.5,318.5z"/>
                    <path className="st1" d="M389.5,307.5c-3,6-3,6-3,6s-6-2-10,4c0,0-17,1-13-15c0,0,1,2,1,6c0,0,0,3,6-1l8-3
                C378.5,304.5,392.5,301.5,389.5,307.5z"/>
                    <path className="st1" d="M287.5,296.5l-13,10c0,0-1,1,1,2s15,4,16,5s-4,2-4,2l-43,1l-3-1h-16c0,0-14-6-14-17l10,8c0,0,21,0,23,2
                c0,0,0,3-11,2s-8,1-8,1s-1,3,10,2s24,0,24,0s12,1,12-3l-23-4c0,0-10-1-12-2s-2-3,3-2s31,5,31,5s9-10,17-13
                C287.5,294.5,289.5,294.5,287.5,296.5z"/>
                    <path className="st1" d="M256.5,311.5c0,0-1-1-10-2c0,0-2,1,0,2S256.5,311.5,256.5,311.5z" />
                    <path className="st1" d="M569.5,248.5l-57-2v-2l28,1c0,0,2-6,1-6s-5-2-3-5c0,0,14,2,12,11l19,1V248.5z" />
                </g>
                <g id="Karosseriebau">
                    <path className="animated_outline" d="M985.5,166.5c-1-3-7-2-7-2s-30,4-34-6s23-13,23-13s3,0,2-4s-1-5-9-4s-40,5-50-2s32-11,32-11s4-1,3-6s-1-6-9-6
                s-42,6-42,6s-18,2-22-2s13-9,13-9s2-2,0-5s-2-3-18-1s-26,4-31,1s-1-9,10-12s20-7,38-3c0,0-6,7-1,9s17,8,21,0s-16-13-16-13
                s-15-6-39-4s-23,4-23,4s-14,4-19-1c0,0,12-15,8-19s-13-2-16,5s-2,12-2,12s-13,5-22,2s-7-3-7-3s-8-2,0-9s17-16,60-26
                c0,0,42-13,59-22c0,0,10-4,6-12s-18-1-18-1s-86,29-101,40s-32,19-46,18s-25-2-28-8s10-5,10-5s17,5,34-1s13-12,13-12s-5-11-35-7
                s-39,16-39,16s-11,8-20,6s-1-10-1-10l16-12c0,0,3-2-1-6s-18,5-18,5l-19,14c0,0-15,9-21,4s23-21,23-21s6-4,4-9s-16-3-16-3l-16,1l2-5
                c0,0,3-6-8-3s-16,14-10,17c0,0-25,25-55,21c0,0-6,1-4-9c0,0-2-2,19-3c0,0,20-4,27-10s2-11,2-11s-3-6-24-1s-31,10-42,22s-12,7-12,7
                s2-1,2-10s0-15,0-15s-2-1,9-7c0,0,1-4-15,1s-27,16-37,28s-13,8-13,8s4-9,1-18s2-11,2-11s3,2,6-2c0,0,1-5-7-2s-22,7-34,21
                s-11,17-22,18c0,0,9-16,1-17s-14,10-13,14s2,4,2,4s2,2-4,6s-21,11-17-1s16-21,16-21l10-7c0,0,6-2-1-4s-36,13-45,33c0,0-1,2-2,7
                c0,0-16,16-18,9s14-25,14-25s2-4-1-7s-9-3-13,0s-16,9-16,9s0-8-2-7s-11,1-15,15c0,0-3,4,5,6c0,0-16,30-24,30c0,0-5-3,2-12
                c0,0,2-2-2-5s-7,1-9,4s-15,25-20,26s-8,4-6-6s21-32,21-32s3-2,3,2s6,3,6,3s8-1,10-8s2-10-6-8s-33,16-44,35s-11,21-10,33
                c0,0,1,2-1,2s-69,5-69,5s35-33,83-110l7-12c0,0,3-6-1-10s-6-1-6-1s-29,53-76,110c0.3-1,10.9-37.1,7-49c-4-12-21-1-21-1
                s-51,39-63,60c0,0-2,3-1,4s2,2,5,1c0,0,8-2,19-15s41-38,41-38s6-4,6,5s-28,110-28,110s-4,9,3,11s8-2,11-9s11-41,11-41l55-6
                c0,0,14-1,21-14c0,0,6,3,16-6s17-18,17-18s2,4,8,4s19-5,28-20s13-23,13-23l21-13c-0.5,0.6-17,19.1-16,28c1,9,7,11,19,4s16-12,16-12
                s4,5,10,5s20,0,38-19c0,0,15-2,18-6s18-23,25-25c0,0,0,22-3,23s-23,3-23,12s29-2,29-2s3-5,14-9s24-19,24-19l14-12c0,0,0,17-5,24
                c0,0-19,1-24,9c0,0-3,5,8,5s29-6,42-14c0,0,2-1,3,2s14,10,27,9s42-7,58-29l2-2c0,0,4,3,17-1l6-1c0,0-29,15-26,23s9,9,19,9
                s15-4,15-4s3,8,18,8s15,0,22-4c0,0,3-1,5,2s5,11,22,13s34,0,42-3c0,0,4-3,5,3s7,15,18,16s23,0,30-5c0,0,3-1,5,1s8,2,8,2s-3,9,1,12
                s11,11,38,7c0,0-3,9,11,15s22,4,22,4s-2,8,6,12s21,9,34,7c0,0-5,12,12,20s49,4,49,4S986.5,169.5,985.5,166.5z M717.5,43.5
                c19-8,24-1,24-1c-8,7-27,8-27,8S698.5,51.5,717.5,43.5z M553.5,26.5c12-6,17-8,21-6c0,0-9,10-27,15c0,0-4,1-5,0
                S541.5,32.5,553.5,26.5z"/>
                    <path className="animated_outline" d="M693.5,8.5c0,0-17,7-10,13s21,2,25-3s6-9-2-10S693.5,8.5,693.5,8.5z" />
                </g>
            </StyledSVG>
        </React.Fragment>
    )
}



// import React from 'react'
// import Baffle from "baffle-react";
// import handleViewport from 'react-in-viewport'

// class Baffle_Text extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             value: 0,
//             inViewport: false,
//             animation_complete: false,
//             obfuscate: true,
//             force: false
//         }
//     }

//     componentDidUpdate() {
//         if (this.state.inViewport !== this.props.inViewport && (!this.state.animation_complete)) {
//             this.setState({inViewport: this.props.inViewport})
//             this.setState({animation_complete: true})
//             this.setState({obfuscate: false})
//             this.forceUpdate()
//             if (this.props.callMethodTime) {
//                 this.parentMethod()
//             }
//         }
//     }

//     forceUpdate() {
//         const { revealDuration, revealDelay } = this.props
//         setTimeout(() => { 
//             this.setState({force:true})
//         }, revealDuration+revealDelay);
//     }

//     parentMethod() {
//         const { callMethodTime } = this.props
//         setTimeout(() => { 
//             this.props.parentMethod()
//         }, callMethodTime);
//     }

//     shouldComponentUpdate(nextProps, nextState) {
//         if (this.state.animation_complete || this.state.force || !this.props.inViewport) {
//             return false
//         } else {
//             return true
//         }
//     }

//     render() {
//         return(
//             <span className="baffle_text">
//                 {this.text()}
//             </span>
//         )
//     }

//     text() {
//         const { text, revealDuration, revealDelay } = this.props
//         if (!this.state.force) {
//             return (
//                 <Baffle
//                     speed={50}
//                     characters={this.props.characters ? this.props.characters : "ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*"}
//                     obfuscate={this.state.obfuscate}
//                     update={true}
//                     revealDuration={revealDuration}
//                     revealDelay={revealDelay}
//                 >
//                     {text}
//                 </Baffle>
//             )
//         } else {
//             return <span>{text}</span>
//         }
//     }
// }
// const BaffleText = handleViewport(Baffle_Text);

// export default BaffleText